<template>
  <section class="multiple-form-items">
    <el-form-item
      v-for="(item, i) in computedItems"
      :key="i"
      :label="label"
      :prop="`${field}.${i}`"
      :rules="getRules"
    >
      <div class="multiple-form-items__view">
        <div v-if="!isMobile" class="multiple-form__desktopView">
          <el-row type="flex" align="middle">
            <IqSelect
              v-if="getFiltredList(i).length > 0"
              v-model="computedItems[i]"
              :options="getFiltredList(i)"
              :placeholder="placeholder"
              size="large"
              :disabled="disabled"
              @change="onChange($event, i)"
            />

            <i
              v-if="
                canAdd &&
                !disabled &&
                list.length - 1 !== i &&
                (computedItems.length === 1 ||
                  (computedItems.length > 1 && computedItems.length - 1 === i))
              "
              class="el-icon-circle-plus multiple-form-items__icon multiple-form-items__add"
              @click="$emit('action', -1, field)"
            />
            <i
              v-if="
                canAdd &&
                !disabled &&
                computedItems.length > 1 &&
                computedItems.length - 1 === i
              "
              class="el-icon-error multiple-form-items__icon multiple-form-items__remove"
              @click="$emit('action', i, field)"
            />
          </el-row>
        </div>

        <div v-else class="multiple-form__mobileView">
          <div class="mobileView-selectRow">
            <div class="mobileView-selectRow__select">
              <IqSelect
                v-if="getFiltredList(i).length > 0"
                v-model="computedItems[i]"
                :options="getFiltredList(i)"
                :placeholder="placeholder"
                size="large"
                :disabled="disabled"
                @change="onChange($event, i)"
              />
            </div>

            <div class="mobileView-selectRow__removeButton">
              <CloseIcon
                v-if="
                  canAdd &&
                  !disabled &&
                  computedItems.length > 1 &&
                  computedItems.length - 1 === i
                "
                @icon-clicked="$emit('action', i, field)"
              />
            </div>
          </div>

          <div class="mobileView-addButton">
            <i
              v-if="
                !disabled &&
                list.length - 1 !== i &&
                (computedItems.length === 1 ||
                  (computedItems.length > 1 && computedItems.length - 1 === i))
              "
              class="el-icon-circle-plus multiple-form-items__icon multiple-form-items__add"
              @click="$emit('action', -1, field)"
            >
              <span>Добавить</span>
            </i>
          </div>
        </div>
      </div>
    </el-form-item>
  </section>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { mapGetters } from 'vuex'
import CloseIcon from '@/assets/icons/CloseIconMobile'
import IqSelect from '@/UI/select/IqSelect'
export default {
  name: 'MultipleFormItem',
  components: { IqSelect, CloseIcon },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({ isMobile: GET_IS_MOBILE }),
    computedItems() {
      return this.items
    },
    getRules() {
      return this.isValidate
        ? { required: true, message: this.message, trigger: 'change' }
        : null
    },
  },
  methods: {
    onChange(val, index) {
      this.$emit('set', val, index, this.field)
    },
    getFiltredList(i) {
      const filtredList = this.list.filter(
        item =>
          this.computedItems[i] === item.id ||
          !this.computedItems.includes(item.id),
      )

      if (!filtredList.length) {
        this.$emit('action', i)
      }

      return filtredList
    },
  },
}
</script>

<style lang="sass">
.multiple-form-items
  margin-bottom: 20px
  &__icon
    font-size: 30px
    margin-left: 10px
    cursor: pointer
  &__add
    color: $color-green
  &__remove
    color: $color-red
  .iq-select
    margin-bottom: 0
  .el-form-item
    margin-bottom: 0
    .el-form-item__label
      margin-bottom: 0 !important
    .multiple-form-items__view
      .multiple-form__mobileView
        .mobileView-selectRow
          display: flex
          align-items: center
          justify-content: space-between
          &__select
            width: 100%
          &__removeButton
            display: flex
            align-items: center
            svg
              margin-left: 10px
        .mobileView-addButton
          .multiple-form-items__icon
            margin: 10px 0 0 0
            font-size: 20px
            display: flex
            align-items: center
            column-gap: 5px
            span
              font-size: 14px

@media (max-width: 1199px)
.multiple-form-items
  .el-form-item
    display: flex
    flex-direction: column
    &__content
      margin: 0 !important
</style>
